import connect from "mqtt/dist/mqtt";
import HmacSHA256 from "crypto-js/hmac-sha256";
import SHA256 from "crypto-js/sha256";
import Hex from "crypto-js/enc-hex";

function toTime(time) {
  return new Date(time).toISOString().replace(/[:\-]|\.\d{3}/g, "");
}

function toDate(time) {
  return toTime(time).substring(0, 8);
}
const SigV4Utils = {};
SigV4Utils.sign = function (key, msg) {
  var hash = HmacSHA256(msg, key);
  return hash.toString(Hex);
};

SigV4Utils.sha256 = function (msg) {
  var hash = SHA256(msg);
  return hash.toString(Hex);
};

SigV4Utils.getSignatureKey = function (
  key,
  dateStamp,
  regionName,
  serviceName
) {
  var kDate = HmacSHA256(dateStamp, "AWS4" + key);
  var kRegion = HmacSHA256(regionName, kDate);
  var kService = HmacSHA256(serviceName, kRegion);
  var kSigning = HmacSHA256("aws4_request", kService);
  return kSigning;
};
function getSignedUrl(regionName, awsIotEndpoint, accessKey, secretKey) {
  var time = new Date();
  var dateStamp = toDate(time);
  var amzdate = toTime(time);
  var service = "iotdevicegateway";
  var region = regionName;
  var secretKey = secretKey;
  var accessKey = accessKey;
  var algorithm = "AWS4-HMAC-SHA256";
  var method = "GET";
  var canonicalUri = "/mqtt";
  var host = awsIotEndpoint;
  var credentialScope =
    dateStamp + "/" + region + "/" + service + "/" + "aws4_request";
  var canonicalQuerystring = "X-Amz-Algorithm=AWS4-HMAC-SHA256";
  canonicalQuerystring +=
    "&X-Amz-Credential=" +
    encodeURIComponent(accessKey + "/" + credentialScope);
  canonicalQuerystring += "&X-Amz-Date=" + amzdate;
  canonicalQuerystring += "&X-Amz-SignedHeaders=host";
  var canonicalHeaders = "host:" + host + "\n";
  var payloadHash = SigV4Utils.sha256("");
  var canonicalRequest =
    method +
    "\n" +
    canonicalUri +
    "\n" +
    canonicalQuerystring +
    "\n" +
    canonicalHeaders +
    "\nhost\n" +
    payloadHash;
  var stringToSign =
    algorithm +
    "\n" +
    amzdate +
    "\n" +
    credentialScope +
    "\n" +
    SigV4Utils.sha256(canonicalRequest);
  var signingKey = SigV4Utils.getSignatureKey(
    secretKey,
    dateStamp,
    region,
    service
  );
  var signature = SigV4Utils.sign(signingKey, stringToSign);
  canonicalQuerystring += "&X-Amz-Signature=" + signature;
  //canonicalQuerystring += '&X-Amz-Security-Token=' + encodeURIComponent(AWS.config.credentials.sessionToken);
  return "wss://" + host + canonicalUri + "?" + canonicalQuerystring;
}

export function client(key, secret, on) {
  const client = connect(
    "wss://a2rxme8um7p462-ats.iot.us-east-1.amazonaws.com",
    {
      transformWsUrl: function (url, options, client) {
        return getSignedUrl(url.split(".")[2], new URL(url).host, key, secret);
      },
    }
  );
  if (!on) return client;

  if (on.message)
    client.on("message", function (topic, payload) {
      //console.log("mesage", topic, JSON.parse(payload.toString()));
      on.message(JSON.parse(payload.toString()));
    });
  client.on("error", (e) => {
    console.log("mqtt.error=", e);
    if (on.error) on.error(e);
  });
  client.on("connect", function (e) {
    console.log("mqtt.connect=", e);
    if (on.connect) on.connect(e);
  });
  client.on("reconnect", function (e) {
    console.log("mqtt.connect=", e);
    if (on.reconnect) on.reconnect(e);
  });
  client.on("disconnect", function (e) {
    console.log("mqtt.connect=", e);
    if (on.disconnect) on.disconnect(e);
  });
  client.on("close", function (e) {
    console.log("mqtt.connect=", e);
    if (on.disconnect) on.disconnect(e);
  });
  client.on("end", function (e) {
    console.log("mqtt.connect=", e);
    if (on.close) on.close(e);
  });
  client.on("offline", function (e) {
    console.log("mqtt.connect=", e);
    if (on.offline) on.offline(e);
  });

  return client;
}

export function topic(id, key, secret, onmessage) {
  const client = connect(
    "wss://a2rxme8um7p462-ats.iot.us-east-1.amazonaws.com",
    {
      transformWsUrl: function (url, options, client) {
        return getSignedUrl(url.split(".")[2], new URL(url).host, key, secret);
      },
    }
  );
  client.on("message", function (topic, payload) {
    //console.log("mesage", topic, JSON.parse(payload.toString()));
    onmessage(JSON.parse(payload.toString()));
  });
  client.on("error", (error) => {
    console.log(error);
  });
  client.on("connect", function () {
    client.subscribe("locations/" + id);
  });

  return client;
}
