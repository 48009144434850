export function processPrices(geo, items) {
  if (!items) return;

  const pricesBySubject = Object.values(items).reduce((map, item) => {
    if (!item.subject) return map;
    map[item.subject] = item;
    return map;
  }, {});

  const features = geo.features || geo;

  for (const feature of features) {
    var price =
      pricesBySubject[feature.properties.id] ||
      pricesBySubject[feature.properties["ref:boss:subject"]];
    if (!price) continue;

    Object.assign(feature.properties, {
      fee: "yes",
      charge: price.total.display.replace(".00", ""),
    });
  }

  return geo;
}

export function processUnitRecords(geo, items) {
  if (!items) return;

  const recordsByKey = Object.values(items).reduce((map, item) => {
    map[item.key] = item;
    map[item.id] = item;
    return map;
  }, {});

  const features = geo.features || geo;

  for (const feature of features) {
    if (!feature.properties || !feature.properties["addr:unit"]) continue; // filter

    var record =
      recordsByKey[feature.properties.id] ||
      recordsByKey[
        feature.properties["addr:unit"].toUpperCase().replace(/[^A-Z0-9]/gi, "")
      ] ||
      recordsByKey[feature.properties.ref];

    // match on unit number if no key
    if (!record)
      record = Object.values(items).find((item) => {
        return item["addr:unit"] == feature.properties["addr:unit"];
      });

    if (!record) continue;

    Object.assign(feature.properties, {
      id: record.id,
      name: record.display,
      ref: record.key,
      ["format"]: record.format,
      ["ref:key"]: record.key,
      ["ref:boss:subject"]: record.id,
    });
  }

  return geo;
}

export function processSpaceRecords(geo, items) {
  //console.log("processSpaces=", data);

  if (!items) return;

  const recordsByKey = Object.values(items).reduce((map, item) => {
    map[item.key] = item;
    map[item.id] = item;
    if (item.key === "VISITOR") map["GUEST"] = item;
    if (item.key === "GUEST") map["VISITOR"] = item;
    return map;
  }, {});

  //console.log("spaces by key = ", recordsByKey);

  const features = geo.features || geo;

  for (const feature of features) {
    if (!feature.properties) continue;

    if (
      feature.properties["amenity"] !== "parking_space" &&
      feature.properties["amenity"] !== "parking_space_entrance" &&
      feature.properties["amenity"] !== "parking_space_rear"
    )
      continue; // filter

    //console.log("processing space feature=", feature);

    var record =
      recordsByKey[feature.properties.id] ||
      recordsByKey[feature.properties.ref] ||
      recordsByKey[normalizeKey(feature.properties.name)];
    if (!record) continue;

    Object.assign(feature.properties, {
      id: record.id,
      name: record.display,
      ref: record.key,
      capacity: record.capacity,
      ["ref:key"]: record.key,
      ["ref:boss:subject"]: record.id,
    });

    if (record.format != "parking") {
      feature.properties.parking_space = record.format;
    }

    if (record.size) feature.properties["size"] = record.size;
    if (record.tags.includes("accessible"))
      feature.properties["capacity:disabled"] = "yes";
    if (record.tags.includes("power"))
      feature.properties["capacity:charging"] = "yes";
  }

  return geo;
}

export function processSpacePermitStatus(geo, status) {
  if (!geo || !status) return geo;

  const permittedBySubject = status;
  //console.log("permitted=", status);

  const features = geo.features || geo;

  for (const feature of features) {
    //console.log("permitted feature? = ", feature);

    if (!feature.properties["ref:boss:subject"]) continue;

    feature.properties["access"] = "permit";

    if (feature.properties.capacity > 1) continue;

    if (feature.properties.policy) continue; // filter out policies

    var itemStatus =
      permittedBySubject[feature.properties["ref:boss:subject"]] ||
      permittedBySubject[feature.properties.id];
    //if(!itemStatus) continue;

    const hasPermit = itemStatus; // && (itemStatus.length || itemStatus.tenant || itemStatus.media || itemStatus.vehicle);
    const hasUnitPermit =
      itemStatus &&
      (itemStatus.length
        ? itemStatus[0].tenant === true
        : itemStatus.tenant === true);
    const hasVehiclePermit =
      itemStatus &&
      (itemStatus.length
        ? itemStatus[0].vehicle === true
        : itemStatus.vehicle === true);
    const hasMediaPermit =
      itemStatus &&
      (itemStatus.length
        ? itemStatus[0].media === true
        : itemStatus.media === true);

    if (hasPermit) feature.properties["permit"] = "yes";
    else feature.properties["permit"] = "no";

    if (hasUnitPermit) feature.properties["permit:unit"] = "yes";
    else delete feature.properties["permit:unit"];

    if (hasVehiclePermit) feature.properties["permit:vehicle"] = "yes";
    else delete feature.properties["permit:vehicle"];

    if (hasMediaPermit) feature.properties["permit:media"] = "yes";
    else delete feature.properties["permit:media"];
  }

  return geo;
}

export function processUnitPermitStatus(geojson, permitSubjectMap) {
  //console.log("knock=", unitStatus);

  if (!geojson || !permitSubjectMap || !Object.keys(permitSubjectMap).length)
    return geojson;

  const features = geojson.features || geojson;

  for (const feature of features) {
    if (
      !feature.properties ||
      !feature.properties["addr:unit"] ||
      !feature.properties["ref:boss:subject"]
    )
      continue;

    const id = feature.properties["ref:boss:subject"];

    let permits = false;
    let vehicle = false;

    for (const subject of Object.values(permitSubjectMap[id] || {})) {
      if (permits && vehicle) continue;
      permits = true;
      if (subject.type == "vehicle") vehicle = true;
    }

    if (permits) feature.properties["parking:permit"] = "yes";
    else feature.properties["parking:permit"] = "no";

    if (vehicle) feature.properties["parking:permit:vehicle"] = "yes";
    else delete feature.properties["parking:permit:vehicle"];
  }

  return geojson;
}

export function processUnitStatus(geojson, unitStatus, explicitUnavailable) {
  //console.log("knock=", unitStatus);

  if (!geojson || !unitStatus || !Object.keys(unitStatus).length)
    return geojson;

  const features = geojson.features || geojson;

  for (const feature of features) {
    if (!feature.properties || !feature.properties["addr:unit"]) continue;
    const unit = feature.properties["addr:unit"];
    if (!unitStatus[unit] && explicitUnavailable) {
      feature.properties.available = "no";
      continue;
    }

    // copy data
    Object.assign(feature.properties, unitStatus[unit]);
  }

  return geojson;
}
export function processSpacePolicies(geo, data) {
  if (!data || !data.policies || !data.policies.items) return;

  data.policies.map = {};

  const features = geo.features || geo;

  features.forEach(function (feature) {
    if (
      feature.geometry.type != "Polygon" ||
      feature.properties.amenity != "parking_space" ||
      !feature.properties["ref:boss:subject"] ||
      !feature.properties.ref
    )
      return; // nothing to check

    const id = feature.properties["ref:boss:subject"];

    let temporary = false;
    let continuous = false;
    let exclusive = false;

    // find matching policy
    for (const policy of Object.values(data.policies.items)) {
      if (!policy.space) continue;
      if (
        !(
          (
            (policy.space.item &&
              policy.space.item == feature.properties.ref) || // // single space
            (policy.space.request &&
              policy.space.items &&
              policy.space.items[feature.properties.ref]) || // multiple spaces
            (policy.space.request && !policy.space.item && !policy.space.items)
          ) // any space
        )
      )
        continue;

      // if policy requires space to be unpermitted, consider it as functionally controlling assignment
      if (policy.space.unpermitted && policy.space.unpermitted.required)
        exclusive = true;

      //feature.properties.policy = "yes";

      if (policy.permit.temporary) temporary = true;
      if (policy.permit.continuous) continuous = true;

      data.policies.map[id] = Object.assign({}, data.policies.map[id] || {}, {
        [policy.policy]: policy,
      });

      //return; // done processing feature
    }

    // fell through to no matching policy
    if (!continuous && !exclusive && temporary)
      feature.properties.policy = "temporary";
    else delete feature.properties.policy;
  });
}

export function processFeatures(input, output, levels, spaces, units) {
  if (!input) return output;

  if (input.features) input = input.features;
  const features = output.features || output;

  for (let i = 0; i < input.length; i++) {
    const feature = Object.assign({}, input[i], {
      properties: { ...input[i].properties },
    });

    // process single-level
    if (
      null != feature.properties.level &&
      "" !== feature.properties.level &&
      !(
        feature.properties.level.includes &&
        feature.properties.level.includes(";")
      )
    ) {
      const l = feature.properties.level + "";
      if (!levels[l] || feature.properties["level:ref"])
        levels[l] = feature.properties["level:ref"] || levels[l] || l;
    }

    // check level
    //if(!isInLevel(feature, level)) continue;

    //if((null != feature.properties.level ? feature.properties.level : level) != level) continue;

    //const hasLevel = !!item && !!item.properties && item.properties.hasOwnProperty("level") && item.properties.level != null;
    //if (hasLevel && null != level && item.properties.level != level) continue; // if it has a level, it has to match if we've specified one

    features.push(feature);

    if (feature.properties.subject) {
      feature.properties["ref:boss:subject"] = feature.properties.subject;
    }

    if (
      spaces &&
      spaces.push &&
      (true ||
        feature.geometry.type === "Polygon" ||
        feature.geometry.type === "LineString") &&
      (feature.properties.amenity === "parking_space" ||
        feature.properties.amenity === "parking_space_entrance")
    )
      spaces.push(feature);

    if (feature.properties["parking_space"] === "disabled") {
      feature.properties["capacity:disabled"] = "yes";
      delete feature.properties.parking_space;
    }

    const unitid = feature.properties["addr:unit"];
    // try pushing unit if there is one
    if (unitid && units && units.push) units.push(feature);
  }

  if (levels) nameLevels(levels);

  return output;
}

function polygon(feature) {
  return feature.geometry.type == "Polygon";
}

export const filters = {
  ev: function (feature, value) {
    return polygon(feature) && feature.properties["capacity:charging"] == value;
  },
  ada: function (feature, value) {
    return polygon(feature) && feature.properties["capacity:disabled"] == value;
  },
  policy: function (feature, policy) {
    return false;
  },
  parking_space: function (feature, policy) {
    return polygon(feature) && feature.properties["amenity"] == "parking_space";
  },
  access: function (feature, value) {
    return polygon(feature) && feature.properties["access"] == value;
  },
  size: function (feature, value) {
    return polygon(feature) && feature.properties["size"] == value;
  },
};

function normalizeKey(str) {
  if (!str || typeof str !== "string") return str;
  return str.toUpperCase().replace(/[^0-9A-Z]/g, "");
}

function nameLevels(levels) {
  for (const [level, name] of Object.entries(levels)) {
    if (name.indexOf("Floor") >= 0) continue;
    if (name.indexOf("Level") >= 0) continue;
    if (name.indexOf("floor") >= 0) continue;
    if (name.indexOf("level") >= 0) continue;
    if (name && name != level) continue;
    levels[level] = "Floor " + name;
  }
  return levels;
}

export function levelRefs(geo, levels) {
  const features = geo.features || geo;

  for (const feature of features) {
    if (null == feature.properties.level || "" == feature.properties.level) {
      feature.properties["level:ref"] = "Outside";
      continue;
    }

    if (feature.properties["level:ref"]) continue;

    if (feature.properties.level.split) {
      console.log("multilevel=", feature.properties.level);
      let featureLevels = feature.properties.level.split(";");
      feature.properties["level:ref"] = levels[featureLevels[0]];
    } else if (!levels[feature.properties.level]) continue;
    // no level
    else feature.properties["level:ref"] = levels[feature.properties.level];
  }

  return geo;
}

function isInLevel(feature, level) {
  // level has to exist and cannot be null, undefined, or "" -- it can be 0
  const hasLevel =
    !!feature &&
    !!feature.properties &&
    feature.properties.hasOwnProperty("level") &&
    feature.properties.level != null;
  // && feature.properties.level !== "";
  if (!hasLevel) return true;

  //if(feature.properties.level == "" && (level == "" || null == level))

  // has level
  //if(null == level) return false; // but we didnt' supply one

  const testLevel = null == level ? "" : level + "";

  // do string comparison
  for (const l of feature.properties.level.split
    ? feature.properties.level.split(";")
    : [feature.properties.level]) {
    //if(feature.properties.amenity == "parking_entrance") console.log("parking entrance level", l+"", "=", testLevel, (l+"") === testLevel);

    if (l + "" === testLevel) return true;
  }

  return false;
}

export function filterLevel(geo, level) {
  //console.log("filtering level = ", level);

  const features = (geo.features || geo).reduce((features, feature) => {
    if (isInLevel(feature, level)) features.push(feature);

    return features;
  }, []);

  return Object.assign({}, geo, {
    features,
  });
}

export function processSelected(geojson, selected) {
  const features = geojson.features || geojson;
  for (const feature of features) {
    if (!["Point", "Polygon"].includes(feature.geometry.type)) continue;

    if (
      feature.properties["ref:boss:subject"] &&
      selected.includes(feature.properties["ref:boss:subject"])
    )
      feature.properties.selected = "yes";
    else if (feature.id && selected.includes(feature.id))
      feature.properties.selected = "yes";
    else delete feature.properties.selected;
  }
}

function isInDelimited(findValue, delimitedValues) {
  console.log("isInDelimited", findValue, delimitedValues);
  if (!delimitedValues) return false;
  if (findValue == delimitedValues) return true;
  if (!!delimitedValues.split)
    return delimitedValues.split(";").some((valueOrRange) => {
      console.log("test", valueOrRange, findValue, valueOrRange == findValue);
      if (valueOrRange == findValue) return true;
      const rangeStartEnd = valueOrRange.split("-");
      if (rangeStartEnd.length < 2) return false;
      return findValue >= rangeStartEnd[0] && findValue <= rangeStartEnd[1];
    });
  return false;
}

function doesHandlePost(post, addr) {
  // first does street number match
  if (
    !addr["addr:housenumber"] ||
    !isInDelimited(addr["addr:housenumber"], post["post:housenumber"])
  )
    return false;
  if (!addr["addr:street"] || addr["addr:street"] != post["post:street"])
    return false;

  // do we check city, state, unit, flats, boxes?

  return true;
}

export function processAddrPost(geojson, addr) {
  const features = geojson.features || geojson;
  for (const feature of features) {
    if (
      feature.properties["amenity"] != "post_depot" &&
      feature.properties["amenity"] != "letter_box"
    )
      continue;

    var access = false;

    // the goal here is #access=permissive, #post:box=###

    console.log("processing post addr", feature.properties, addr);

    if (addr && addr["post:box"]) {
      // we know it has a box
      // does it match this feature?

      console.log("checking post:box=", addr, feature.properties);

      if (doesHandlePost(feature.properties, addr)) {
        // addr can access this post feature

        access = true;
        if (addr["post:box"]) {
          feature.properties["post:box"] = addr["post:box"];
          feature.properties.name = `Mailbox ${addr["post:box"]}`;

          // update the address info
          // for(const [ key, value ] of Object.entries(addr)) {
          //     if(!key.startsWith("addr:")) continue;
          //     feature.properties[key] = value;
          // }
          // feature.properties["addr:unit"] = unitPrefixed(feature.properties["addr:unit"], addr.format);

          //feature.properties.description = `Mailing address: ${addr["addr:housenumber"]} ${addr["addr:street"]}${addr["addr:unit"] ? (" " + unitPrefix(addr.format) + " " + addr["addr:unit"]) : ""}, ${addr["addr:city"]} ${addr["addr:state"]}, ${addr["addr:postcode"]}`;
        }
      }

      // const post = `${feature.properties["post:housenumber"]||""}${feature.properties["post:street"]||""}${feature.properties["post:unit"]||""}`;
      // //console.log("post=", post)
      // if(!!post && post == `${addr["addr:housenumber"]||""}${addr["addr:street"]||""}`) {

      // }

      // overwrite box
    }

    // update access
    feature.properties["access"] = access ? "permissive" : "no";
  }

  return geojson;
}
