import { writable, derived, readable } from "svelte/store";
import { param } from "./params";
import { items } from "@parkingboss/svelte-utils";
import {
  fetchAndStoreProperty,
  fetchAndStorePolicies,
  fetchAndStoreUnits,
  fetchAndStoreSpaces,
  fetchUnitsAvailability,
} from "../api";

import { updated as monitor } from "./updated";

export const view = param("view", true);
export const valid = param("valid");
export const propertyId = param("property", true);

export { param, items };

export const units = writable([]);
export const availability = writable({});
//export const property = writable(null);

// create a derived function to load property data on change

const propertyData = derived(
  propertyId,
  async function onchange($propertyId, set) {
    if (!$propertyId) return set(null);

    set(await fetchUnitsAvailability($propertyId));
  }
);
export const property = derived(
  [propertyId, propertyData],
  ([$propertyId, data], set) => {
    availability.set(data?.availability ?? {});
    units.set(Object.values(data?.items ?? []).filter((i) => i.type == "unit"));
    set(data?.items[$propertyId]);
  }
);

// .subscribe((data) => {
//   property.set(data?.items[$propertyId]);
//   availability.set(data?.availability ?? {});
//   units.set(Object.values(data?.items ?? []).filter((i) => i.type == "unit"));
// });

// propertyId.subscribe(async ($propertyId) => {
//   if (!$propertyId) return;

//   var data = await fetchUnitsAvailability($propertyId);

//   property.set(data.items[$propertyId]);

//   // fetchAndStoreProperty($propertyId);
//   // fetchAndStoreUnits($propertyId);
//   // fetchAndStoreSpaces($propertyId);
// });

property.subscribe(($v) => console.log("property=", $v));

export const updated = derived(property, function onchange($property, set) {
  if (!$property) return set(null);

  const unsubscribe = monitor($property.id, set); // get the monitor
  // const unsubscribe = monitor.store.subscribe($value => {

  //     // smart check value
  //     if($value.scope == monitor.scope && $value.updated == $lastUpdated.updated) return;

  //     lastUpdated = $value;
  //     set($value);

  // }); // proxy

  return function nextchange() {
    if (unsubscribe) unsubscribe();
  }; // clear
});
