<script>
    import { onMount, onDestroy, getContext } from "svelte";
	import { mapbox, key } from "./mapbox.js";

	const { getMap } = getContext(key);
	const map = getMap();
	let pre;

	$: if(map) map.on('mousemove', function (e) {
        if(!pre) return;
        var features = map.queryRenderedFeatures(e.point);
        
        // Limit the number of properties we're displaying for
        // legibility and performance
        var displayProperties = [
        'type',
        'properties',
        'id',
        'layer',
        'source',
        'sourceLayer',
        'state'
        ];
        
        var displayFeatures = features.map(function (feat) {
        var displayFeat = {};
        displayProperties.forEach(function (prop) {
        displayFeat[prop] = feat[prop];
        });
        return displayFeat;
        });
        
        pre.innerHTML = JSON.stringify(
        displayFeatures,
        null,
        2
        );
    });
	
	// function onMouse(e) {
		
	// }

	// onMount(() => {

	// });

	// onDestroy(() => {

	// });

</script>
<pre bind:this={pre}></pre>