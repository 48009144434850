<script>

    import { onMount, onDestroy, getContext } from "svelte";
	import { mapbox, key } from "./mapbox.js";


	const { getMap } = getContext(key);
    const map = getMap();

	export let attribution;

	let control;

	$: control = update(attribution);

	function update(attribution) {
		const newControl = new mapbox.AttributionControl({
            compact: true,
			customAttribution:attribution
        });
		if(control) console.log("prev attr=", control);
		if(control) map.removeControl(control);
		map.addControl(newControl);
		//control = newControl;
		return newControl;
	}


	onDestroy(function() {
		if(control && map) map.removeControl(control);
		control = null;
	});



</script>