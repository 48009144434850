<script context="module">
import { param, params } from "./util/params";
import { derived } from "svelte/store";
export const inspect = derived(param("inspect"), $value => $value === "true" || $value === true);
</script>
<script>
    import Map from "./components/maps/Map.svelte";
    import MapCompass from "./components/maps/MapCompass.svelte";
    import MapMarker from "./components/maps/MapMarker.svelte";
    import MapPopup from "./components/maps/MapPopup.svelte";
    import MapPitch from "./components/maps/MapPitch.svelte";
    import MapInspector from "./components/maps/MapInspector.svelte";
    import MapAttribution from "./components/maps/MapAttribution.svelte";
    import { propertyGeoJsonStatic, level } from "./components/maps/stores";
    import { query } from "./util/router";
    import { property, availability, units } from "./util/stores";
    import { items } from "@parkingboss/svelte-utils";
    import { processFeatures, filterLevel } from "./util/geopipeline";
    import { comparer, numericAsc } from "./util/sort";
    import { scaleBbox, bbox } from "./util/geo";
    import style from "./components/maps/greyscale";

    const currentStyle = style();


    let mapbox;
    let attribution;

    let sources = {};
    let levels = {};
    let points = [];
    let activeBbox;
    let selectedLevel;
    let selectedPoints = [];
    let availableUnits = [];

    $:console.log('levels=', levels);


    $: title = document.title = $property ? `${$property ? $property.name + " " : ""} Map - Community Boss` : "Loading…";

    // $: if($propertyGeoJsonDynamic) dynamicFeatures = $propertyGeoJsonDynamic.features.reduce((result, item) => {
    //     result[item.id] = item;
    //     return result;
    // }, {});

    //$: console.log("dynamic features = ", dynamicFeatures);

    // run pipeline on any change
    $: features = pipeline([ $propertyGeoJsonStatic ], $items, $units, $availability);

    $: selectAndLevel(features, points, $level, [  ], $params);

    function pipeline(geojson, state, units, availability) {

        if(!geojson) return;

        const spaceFeatures = [];
        const unitFeatures = [];
        let features = [];
        //const newLevels = {};

        attribution = geojson.reduce((result, geojson) => (geojson.properties && geojson.properties.branch ? `${geojson.properties.branch} ${geojson.properties.commit} ${geojson.properties.build}` : result), null);

        const allFeatures = geojson.flatMap(geojson => (geojson && geojson.features) || geojson || []);

        console.log("bboxing all features=", allFeatures);

        activeBbox = allFeatures.length && bbox({
                "type":"FeatureCollection",
                "features":allFeatures
            });
        console.log("bbox=", activeBbox);

        processFeatures(allFeatures, features, levels, spaceFeatures, unitFeatures);

        const buildAvailableUnits = [];

        for(const feature of unitFeatures) {

            const unitAvail = availability?.["for"]?.[feature.properties["ref:boss:subject"]];
            if(!unitAvail) continue;

            console.log("uf=", feature, unitAvail);

            feature.properties["fee"] = "yes";
            
            if(feature.geometry.type == "Point") buildAvailableUnits.push(feature);

        }

        availableUnits = buildAvailableUnits;

        levels = levels;

        return features;
    }

    // run the selection/leveling
    function selectAndLevel(features, points, level, selected) {

        console.log("starting select and level", features, points, level, selected);

        if(!features || !features.length) return;
        //if(!points || !points.length) return;

        // run selection against all features
        // this calcs on each run
        //processSelected(features, selected);

    
        // optimize for already selected level
        // this calcs conditionally
        // if(levelFeatures && levelFeatures.length && level === selectedLevel) {
        //     console.log("selected=", selectedLevel, "level=", level, "same level, skipping, keep previous features");
        // } else {
            //console.log("selected=", selectedLevel, "level=", level, "filtering to new level");
            const levelFeatures = filterLevel(features, level).features;

            // levelPoints = filterLevel(points.concat(Object.values(unitPoints).filter(feature => "yes" == feature.properties.selected)), level).features.concat();
            selectedLevel = level;
            //hasProcessedLevel = true;
        //}

        // run after any releveling, selected will be updated previously
        // this calcs on each run
        //selectedPoints = levelPoints.filter(feature => "yes" == feature.properties.selected);

        console.log("about to render sources, property=", levelFeatures);
        sources = Object.assign(sources, {
            property: {
                "type":"geojson",
                "generateId":true,
                "data":{
                    "type":"FeatureCollection",
                    "features":levelFeatures
                },
                "attribution":attribution
            }
        });
        console.log("just set sources =", sources);

    }

    // selectable UI
    $: if(mapbox) {

        //mapbox.setPadding({ left: 288 });
        let hovered = null;
        mapbox.dragRotate.disable();
 
        // disable map rotation using touch rotation gesture
        mapbox.touchZoomRotate.disableRotation();
        mapbox.touchPitch.disable();

        // click to select
        mapbox.on("click", "selectable", function(e) {
            //console.log("clicked selectable=", e.features);
            const feature = e.features[0];
            if(!feature) return;

            if(feature.properties["ref:boss:subject"]) {
                return query("selected", feature.properties["ref:boss:subject"]);
            }

            if(feature.properties["addr:unit"] && feature.properties["ref:boss:subject"]) {
                query("unit", feature.properties["ref:boss:subject"]);
            };

            if(feature.properties["amenity"] == "parking_space" && feature.properties["ref:boss:subject"]) {
                query("space", feature.properties["ref:boss:subject"]);
            };

        });

        mapbox.on("mouseenter", "selectable", e => {
            mapbox.getCanvas().style.cursor = "pointer";
        });
        mapbox.on("mousemove", "selectable", e => {

            if(!e.features || !e.features.length) return;

            if(hovered && hovered.id == e.features[0].id) return; // already hovered

            if (hovered) {
                mapbox.setFeatureState(
                    hovered,
                    { hover: false }
                );
            }
            hovered = e.features[0];
            mapbox.setFeatureState(
                hovered,
                { hover: true }
            );

        })
        mapbox.on("mouseleave", "selectable", e => {
            mapbox.getCanvas().style.cursor = "";
            if (hovered) {
                mapbox.setFeatureState(
                    hovered,
                    { hover: false }
                );
                hovered = null;
            }
        });

    }

    function floorName(level=null) {
        if(null == level || "" == level) return "Outside";
        return  levels[level] || ("Floor " + level)
    }


    
</script>
<svelte:head>
  <title>{title}</title>
</svelte:head>
<header></header>
<main>
<section>
    <!-- <h1>{($property && $property.name) || "Loading…"}</h1> -->

    <form on:submit|preventDefault={e => e}>
        <fieldset>
            <ul>
                <li on:change={e => (e.target.checked && query(e.target.name, e.target.value))}>
                    <label>Level</label>
                    <ul>
                        <li><input id="level-0" type="radio" name="level" checked={!$level} value="" /><label for="level-0">{floorName()}</label></li>
                        {#if levels && Object.keys(levels).length > 0}
                        {#each Object.keys(levels).sort(numericAsc) as id}
                        <li><input id="level-{id}" type="radio" name="level" checked={(""==id && !$level) || (id == $level)} value="{id}" /><label for="level-{id}">{floorName(id)}</label></li>
                        {/each}
                        {/if}
                    </ul>
                </li>
                <li>
                <label>Available</label>
                <ul>
                    {#each availableUnits.sort(comparer("properties.name")) as feature}
                        <li><input id="space-{feature.properties["ref:boss:subject"]}" type="radio" name="selected" checked={feature.properties["ref:boss:subject"] == null} value="{feature.properties["ref:boss:subject"]}" on:change={e => e.target.checked && true} /> <label for="space-{feature.properties["ref:boss:subject"]}"><data class="unit {feature.properties.format||""} id" value="{feature.properties["ref:boss:subject"]}">{feature.properties["name"]}</data></label></li>
                    {/each}
                </ul>
            </li>
            </ul>
            
        </fieldset>
    </form>
</section>
<Map bind:mapbox={mapbox} style={currentStyle} sources={sources} maxBbox={activeBbox && scaleBbox(activeBbox, 4)}>
    <!-- <MapCompass /> -->
    <!-- <MapPitch /> -->
    <MapAttribution attribution={attribution} />
    {#if $inspect}
    <MapInspector />
    {/if}
</Map>
</main>