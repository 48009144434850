import { updateItems } from "@parkingboss/svelte-utils";
import { toZoneISOString } from "./util/datetime";
import { api, auth } from "./util/auth";
import { throttle } from "lodash-es";

export { api };

// update auth header as auth changes
export let authHeader = "";
auth.subscribe(
  ($auth) => (authHeader = $auth && `${$auth.type} ${$auth.token}`)
);

export function viewpoint() {
  return toZoneISOString(new Date());
}

export function base() {
  return api.settings.apiBase;
}

export function authorize() {
  return "&Authorization=" + (authHeader || "");
}

export async function fetchAndStoreProperty(property) {
  const res = await fetch(
    `${base()}/properties?viewpoint=${viewpoint()}&property=${property}`
  );
  const json = await res.json();

  for (const item of Object.values(json.items)) {
    json.items[item.uuid.split("-").join("")] = item;
  }

  updateItems(json);

  return json;
}

export async function fetchAndStorePolicies(property) {
  const res = await fetch(
    `${base()}/permits/policies/issue?scope=${property}&viewpoint=${viewpoint()}&public=true&admin=false&disabled=false`
  );

  const json = await res.json();

  statify(json);

  updateItems(json);

  return json;
}

export async function fetchUnits(scope) {
  if (!scope) return null;

  const res = await fetch(
    `${base()}/units?scope=${scope}&viewpoint=${viewpoint()}`
  );
  const json = await res.json();

  return json;
}

export async function fetchUnitsAvailability(scope) {
  if (!scope) return null;

  const res = await fetch(
    `${base()}/units/availability?scope=${scope}&viewpoint=${viewpoint()}`
  );
  const json = await res.json();

  return json;
}

function statify(data, state) {
  state = state || data;

  for (const [key, value] of Object.entries(data)) {
    if (!value.items) continue;

    state.items[key] = value;

    for (const [id, v] of Object.entries(value.items)) {
      if (typeof v !== "string") continue;
      value.items[id] = data.items[v] || data.items[id] || v;
    }
  }
}

export async function fetchAndStoreUnits(scope) {
  const json = await fetchUnits(scope);

  statify(json);

  updateItems(json);

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchAndStoreUnitsAvailability(scope) {
  const json = await fetchUnitsAvailability(scope);

  statify(json);

  updateItems(json);

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchAndStoreSpaces(scope) {
  const json = await fetchSpaceStatus(scope);

  statify(json);

  updateItems(json);

  return json;
}

export const fetchSpaceStatus = throttle(
  async function fetchSpaceStatus(scope, valid) {
    if (!valid) valid = viewpoint() + "/";

    var url = `${base()}/locations/${scope}/permits/spaces/summary?prices=true&viewpoint=${viewpoint()}&valid=${valid}`;
    //console.log("spaces=", url);

    const res = await fetch(url);
    //console.log("spaces res", res);
    const json = await res.json();

    // copy prices to set by subject
    if (json.prices) {
      for (const [id, v] of Object.entries(json.prices.items)) {
        if (typeof v !== "string") continue;
        const price = (json.prices.items[id] =
          json.items[v] || json.items[id] || v);

        if (price.subject) {
          json.prices.items[price.subject] =
            json.prices.items[price.subject] || [];
          json.prices.items[price.subject].push(price);
        }
      }
    }

    return json;
  },
  3 * 1000,
  {
    leading: true,
    trailing: true,
  }
);

export const fetchPermits = throttle(
  async function fetchPermits(scope, valid) {
    if (!valid) valid = viewpoint() + "/";

    updateItems({
      items: {
        permits: {
          map: {},
        },
      },
    });

    var url = `${base()}/permits?viewpoint=${viewpoint()}&scope=${scope}&valid=${valid}${authorize()}`;
    //console.log("permits=", url);

    const res = await fetch(url);
    //console.log("spaces res", res);

    const json = await res.json();

    if (json && json.permits) resolvePermits(json.permits.items, json.items);

    // meta map
    if (json && json.permits) {
      const metaMap = Object.values(json.permits.items).reduce(
        (map, permit) => {
          if (!permit.continuous) return map; // only continuous?
          for (const subject of permit.subjects) {
            if (!subject.id) continue;
            map[subject.id] = map[subject.id] || {};
            if (subject.subject)
              map[subject.subject.id || subject.subject] =
                map[subject.subject.id || subject.subject] || {};
            for (const other of permit.subjects) {
              if (subject.subject)
                map[subject.subject.id || subject.subject][other.id] = other;
              if (!other.id) continue;
              if (other.id == subject.id) continue; // no self ref
              map[subject.id][other.id] = other;
            }
          }
          return map;
        },
        {}
      );

      //console.log("subject meta map=", metaMap);

      updateItems({
        items: {
          permits: {
            map: metaMap,
          },
        },
      });
    }

    return json;
  },
  3 * 1000,
  {
    leading: true,
    trailing: true,
  }
);

export async function fetchAndStorePrices(scope) {
  const json = await fetchPrices(scope);

  statify(json);

  updateItems(json);
}

export async function fetchPrices(scope) {
  if (!scope) return null;
  const res = await fetch(
    `${base()}/prices?scope=${
      scope.id || scope
    }&viewpoint=${viewpoint()}&valid=${viewpoint(1000 * 60)}/${authorize()}`
  );
  const json = await res.json();

  return json;
}

export async function fetchCreatePrices(
  scope,
  formData,
  fetchAndStore = false
) {
  const res = await fetch(
    `${base()}/prices?scope=${
      scope.id || scope
    }&viewpoint=${viewpoint()}${authorize()}`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok && fetchAndStore) {
    //const json = await res.json();

    await fetchAndStorePrices(scope.id || scope); // refetch
    //return json;
    //return created;
  }

  return await responseJson(res);
}

export async function fetchDeletePrices(
  scope,
  formData,
  fetchAndStore = false
) {
  const res = await fetch(
    `${base()}/prices?viewpoint=${viewpoint()}${authorize()}`,
    {
      method: "DELETE",
      body: formData,
    }
  );
  if (res.ok && fetchAndStore) {
    return await fetchAndStorePrices(scope.id || scope);
  }

  return await responseJson(res);
}

export function resolvePermits(values, items) {
  // values is the list of permits, items is the overall state

  if (!values || !items) return null;

  for (let [id, permit] of Object.entries(values)) {
    if (typeof permit == "string")
      permit = items[permit] || items[id] || permit;

    if (!permit || !permit.id) continue;

    values[id] = Object.assign(permit, {
      property: resolveProperty(
        items[permit.location] || permit.location,
        items
      ),
      address: items[permit.address] || permit.address,
      policy:
        items[permit.issued.policy] ||
        items[permit.issued.issuer] ||
        permit.issued.issuer,
      //vehicle: items[permit.vehicle] || permit.vehicle,
      //media: items[permit.media] || permit.media,
      //spaces: (permit.spaces || []).map(i => items[i] || i),
      subjects: (permit.subjects || []).map((i) => items[i] || i),
      //tenant: items[permit.tenant] || permit.tenant,
    });
  }

  return values;
}

export function resolveAddress(item, items) {
  if (!item) return item;
  item.address = items[item.address] || item.address;
  return item;
}

export function resolveProperty(item, items) {
  if (!item) return item;
  if (typeof item === "string") item = items[item];
  if (item) item.stripe = items.stripe;
  return resolveAddress(item, items);
}
