const point = ["==", "$type", "Point"];

const colors = {
  sand: "#e7c496",
  grass: "#dee9d8",
  paving_stones: "#F9F8F7",
};

const level_area = () => [
  {
    id: "indoor outdoor level area",
    type: "fill",
    source: "property",
    minzoom: 15,
    filter: ["any", ["==", "indoor", "level"], ["==", "outdoor", "level"]],
    layout: {},
    paint: { "fill-color": "#fff", "fill-opacity": 1 },
  },
  {
    id: "level surface",
    type: "fill",
    source: "property",
    minzoom: 15,
    filter: ["all", ["has", "level"], ["has", "surface"]],
    layout: {},
    paint: {
      "fill-color": [
        "case",
        ["==", ["get", "surface"], "paving_stones"],
        colors.paving_stones,
        ["==", ["get", "surface"], "grass"],
        colors.grass,
        ["==", ["get", "surface"], "sand"],
        colors.sand,
        "#fff",
      ],
      "fill-opacity": 1,
    },
  },
  {
    id: "level patio",
    type: "fill",
    source: "property",
    minzoom: 0,
    filter: ["all", ["has", "level"], ["==", "leisure", "outdoor_seating"]],
    layout: {},
    paint: { "fill-color": colors.paving_stones, "fill-opacity": 1 },
  },
];

const parking_entrance = [
  {
    id: "parking entrance icon",
    type: "symbol",
    source: "property",
    minzoom: 15,
    filter: [
      "all",
      ["==", "amenity", "parking_entrance"],
      ["==", "$type", "Point"],
    ],
    layout: {
      "symbol-placement": "point",
      "icon-rotation-alignment": "viewport",
      "icon-keep-upright": true,
      "icon-anchor": ["case", ["has", "name"], "center", "center"],
      "icon-size": ["interpolate", ["linear"], ["zoom"], 17, 0.2, 24, 0.9],
      "icon-allow-overlap": true,
      "icon-ignore-placement": true,
      "icon-image": "parking-entrance-64",
      visibility: "visible",
    },
  },
  {
    id: "parking entrance label",
    type: "symbol",
    source: "property",
    minzoom: 15,
    filter: [
      "all",
      ["==", "amenity", "parking_entrance"],
      ["==", "$type", "Point"],
      ["has", "name"],
    ],
    layout: {
      "text-field": ["get", "name"],
      "text-font": ["Roboto Bold"],
      "text-transform": "uppercase",
      "text-size": ["interpolate", ["linear"], ["zoom"], 17, 7, 24, 21],
      "text-radial-offset": [
        "interpolate",
        ["linear"],
        ["zoom"],
        17,
        1.5,
        24,
        2,
      ],
      "text-line-height": 1,
      "text-max-width": 6,
      "symbol-placement": "point",
      "text-anchor": ["case", ["has", "name"], "top", "center"],
      "text-allow-overlap": false,
      "text-ignore-placement": false,
      visibility: "visible",
    },
    paint: {
      "text-color": "#3D91D4",
      "text-halo-color": "#fff",
      "text-halo-width": 2,
    },
  },
];
const fire_hydrant = [
  {
    id: "fire hydrant icon",
    type: "symbol",
    source: "property",
    minzoom: 15,
    filter: [
      "all",
      ["==", "emergency", "fire_hydrant"],
      ["==", "$type", "Point"],
    ],
    layout: {
      "symbol-placement": "point",
      "icon-rotation-alignment": "viewport",
      "icon-keep-upright": true,
      "icon-anchor": ["case", ["has", "name"], "center", "center"],
      "icon-size": ["interpolate", ["linear"], ["zoom"], 17, 0.2, 24, 0.9],
      "icon-allow-overlap": true,
      "icon-ignore-placement": true,
      "icon-image": "fire-hydrant-64",
      visibility: "visible",
    },
  },
];
function entrance() {
  const f = [
    ["in", "entrance", "yes", "main"],
    ["!=", "amenity", "parking_entrance"],
  ];
  return [
    {
      id: "entrance icon",
      type: "symbol",
      source: "property",
      minzoom: 15,
      filter: ["all", ...f, point],
      layout: {
        "symbol-placement": "point",
        "icon-rotation-alignment": "viewport",
        "icon-keep-upright": true,
        "icon-anchor": ["case", ["has", "name"], "center", "center"],
        "icon-size": ["interpolate", ["linear"], ["zoom"], 17, 0.2, 24, 0.9],
        "icon-allow-overlap": true,
        "icon-ignore-placement": true,
        "icon-image": "entrance-64",
        visibility: "visible",
      },
    },
    {
      id: "entrance label",
      type: "symbol",
      source: "property",
      minzoom: 15,
      filter: ["all", ...f, point, ["has", "name"]],
      layout: {
        "text-field": ["get", "name"],
        "text-font": ["Roboto Bold"],
        "text-transform": "uppercase",
        "text-size": ["interpolate", ["linear"], ["zoom"], 17, 7, 24, 21],
        "text-radial-offset": [
          "interpolate",
          ["linear"],
          ["zoom"],
          17,
          1.5,
          24,
          2,
        ],
        "text-line-height": 1,
        "text-max-width": 6,
        "symbol-placement": "point",
        "text-anchor": ["case", ["has", "name"], "top", "center"],
        "text-allow-overlap": false,
        "text-ignore-placement": false,
        visibility: "visible",
      },
      paint: {
        "text-color": "#000",
        "text-halo-color": "#fff",
        "text-halo-width": 2,
      },
    },
  ];
}

function available() {
  return [
    {
      id: "available outline",
      type: "line",
      source: "property",
      filter: ["all", ["==", "$type", "Polygon"], ["==", "fee", "yes"]],
      minzoom: 15,
      paint: {
        "line-color": "#25bf65",
        "line-width": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          3,
          3,
        ],
        "line-offset": 0,
        "line-opacity": 1,
      },
    },
  ];
}

function selected() {
  return [
    {
      id: "selectable outline",
      type: "line",
      source: "property",
      filter: ["all", ["==", "$type", "Polygon"], ["==", "selectable", "yes"]],
      minzoom: 15,
      paint: {
        "line-color": "#158fff",
        "line-width": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          3,
          1,
        ],
        "line-offset": 0,
        "line-opacity": 1,
      },
    },
    {
      id: "selected highlight outer",
      type: "line",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "selected", "yes"],
        ["in", "$type", "Polygon", "LineString"],
      ],
      layout: {},
      paint: {
        "line-offset": 0,
        "line-color": "#fff",
        "line-width": 4.5,
      },
    },
    {
      id: "selected highlight inner",
      type: "line",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "selected", "yes"],
        ["in", "$type", "Polygon", "LineString"],
      ],
      layout: {},
      paint: {
        "line-offset": 0,
        "line-color": "#df3a25",
        "line-width": 1.5,
      },
    },
    {
      id: "selected highlight outer circle",
      type: "circle",
      source: "property",
      minzoom: 15,
      filter: ["all", ["==", "selected", "yes"], ["in", "$type", "Point"]],
      layout: {
        visibility: "none",
      },
      paint: {
        "circle-radius": 10,
        "circle-opacity": 0,
        "circle-stroke-color": "#fff",
        "circle-stroke-width": 6,
      },
    },
    {
      id: "selected highlight inner circle",
      type: "circle",
      source: "property",
      minzoom: 15,
      filter: ["all", ["==", "selected", "yes"], ["in", "$type", "Point"]],
      layout: {
        visibility: "none",
      },
      paint: {
        "circle-radius": 10,
        "circle-opacity": 0,
        "circle-stroke-color": "#df3a25",
        "circle-stroke-width": 2,
      },
    },
  ];
}

function highlighted() {
  return [
    {
      id: "highlight outer",
      type: "line",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "highlight", "yes"],
        ["in", "$type", "Polygon", "LineString"],
      ],
      layout: {},
      paint: {
        "line-offset": 0,
        "line-color": "#fff",
        "line-width": 4.5,
      },
    },
    {
      id: "highlight inner",
      type: "line",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "highlight", "yes"],
        ["in", "$type", "Polygon", "LineString"],
      ],
      layout: {},
      paint: {
        "line-offset": 0,
        "line-color": "#FFA500",
        "line-width": 1.5,
      },
    },
  ];
}

function parking_space_outline() {
  return [
    {
      id: "parking space basic",
      type: "fill",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "amenity", "parking_space"],
        ["==", "$type", "Polygon"],
      ],
      layout: { visibility: "none" },
      paint: { "fill-opacity": 1, "fill-color": "#e6e6e6" },
    },
    {
      id: "parking space basic outline",
      type: "line",
      source: "property",
      filter: [
        "all",
        ["==", "$type", "Polygon"],
        ["==", "amenity", "parking_space"],
      ],
      paint: { "line-color": "#DEDEDE" },
    },
    {
      id: "parking space permit status area",
      type: "fill",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "amenity", "parking_space"],
        ["==", "$type", "Polygon"],
        ["has", "permit"],
        ["==", "access", "permit"],
      ],
      layout: {},
      paint: {
        "fill-color": [
          "case",
          [
            "all",
            ["==", ["get", "permit:unit"], "yes"],
            ["!=", ["get", "permit:vehicle"], "yes"],
          ],
          "#ffebcc",
          ["==", ["get", "permit"], "yes"],
          "#ffe8e5",
          "#cee5d8",
        ],
        "fill-outline-color": "#fff",
      },
    },
    {
      id: "parking space fee",
      type: "fill",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "amenity", "parking_space"],
        ["==", "$type", "Polygon"],
        ["has", "charge"],
      ],
      layout: { visibility: "visible" },
      paint: { "fill-opacity": 1, "fill-color": "#C8E2FA" },
    },
    {
      id: "parking space visitor",
      type: "fill",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "amenity", "parking_space"],
        ["==", "$type", "Polygon"],
        ["==", "parking_space", "visitor"],
      ],
      layout: { visibility: "none" },
      paint: {
        "fill-opacity": 1,
        "fill-color": "#3a8457",
        "fill-outline-color": "#fff",
      },
    },
    {
      id: "parking space outline",
      type: "line",
      source: "property",
      filter: [
        "all",
        ["==", "$type", "Polygon"],
        ["==", "amenity", "parking_space"],
      ],
      layout: { visibility: "none" },
      paint: { "line-color": "#fff" },
    },
  ];
}

function parking_space_charge() {
  return [
    {
      id: "parking space charge label normal",
      type: "symbol",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "$type", "LineString"],
        ["==", "amenity", "parking_space"],
        ["!in", "parking_space", "storage", "bicycle", "kayak", "motorcycle"],
        ["has", "charge"],
      ],
      layout: {
        "text-size": ["interpolate", ["linear"], ["zoom"], 17, 1, 24, 40],
        "text-allow-overlap": true,
        "text-variable-anchor": ["center"],
        "text-transform": "uppercase",
        "text-font": ["Roboto Condensed Bold"],
        "symbol-placement": "line-center",
        "text-padding": 0,
        "text-rotation-alignment": "map",
        "text-field": ["get", "charge"],
        "text-letter-spacing": 0,
        visibility: "visible",
        "text-line-height": 1,
      },
      paint: {
        "text-halo-color": "#fff",
        "text-halo-width": 1,
        "text-color": "#000000",
      },
    },
    {
      id: "parking space charge label medium",
      type: "symbol",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "$type", "LineString"],
        ["==", "amenity", "parking_space"],
        ["in", "parking_space", "motorcycle"],
        ["has", "charge"],
      ],
      layout: {
        "text-size": ["interpolate", ["linear"], ["zoom"], 18, 0, 24, 30],
        "text-allow-overlap": true,
        "text-variable-anchor": ["center"],
        "text-transform": "uppercase",
        "text-font": ["Roboto Condensed Bold"],
        "symbol-placement": "line-center",
        "text-padding": 0,
        "text-rotation-alignment": "map",
        "text-field": ["get", "charge"],
        "text-letter-spacing": 0,
        visibility: "visible",
        "text-line-height": 1,
      },
      paint: {
        "text-halo-color": "#fff",
        "text-halo-width": 1,
        "text-color": "#000000",
      },
    },
    {
      id: "parking space charge label small",
      type: "symbol",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "$type", "LineString"],
        ["==", "amenity", "parking_space"],
        ["in", "parking_space", "storage", "bicycle", "kayak"],
        ["has", "charge"],
      ],
      layout: {
        "text-size": ["interpolate", ["linear"], ["zoom"], 19, 0, 24, 20],
        "text-allow-overlap": true,
        "text-variable-anchor": ["center"],
        "text-transform": "uppercase",
        "text-font": ["Roboto Condensed Bold"],
        "symbol-placement": "line-center",
        "text-padding": 0,
        "text-rotation-alignment": "map",
        "text-field": ["get", "charge"],
        "text-letter-spacing": 0,
        visibility: "visible",
        "text-line-height": 1,
      },
      paint: {
        "text-halo-color": "#fff",
        "text-halo-width": 1,
        "text-color": "#000000",
      },
    },
  ];
}

function parking_space_label() {
  return [
    {
      id: "parking space label normal exp",
      type: "symbol",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "$type", "Point"],
        ["has", "name"],
        ["has", "heading"],
        ["!has", "charge"],
        ["!in", "parking_space", "storage", "bicycle", "kayak", "motorcycle"],
        ["==", "amenity", "parking_space"],
      ],
      layout: {
        visibility: "none",
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          15,
          ["*", 24, ["^", 2, -6]],
          24,
          ["*", 24, ["^", 2, 8]],
        ],
        "text-allow-overlap": true,
        "text-variable-anchor": ["center"],
        "text-transform": "uppercase",
        "text-font": ["Roboto Condensed Bold"],
        "symbol-placement": "point",
        "text-padding": 0,
        "text-rotate": ["-", ["get", "heading"], 90],
        "text-rotation-alignment": "map",
        "text-field": ["get", "name"],
        "text-letter-spacing": 0,
        "text-line-height": 1,
      },
      paint: {
        "text-halo-color": "#fff",
        "text-halo-width": 1,
        "text-color": "#000000",
      },
    },
    {
      id: "parking space label normal",
      type: "symbol",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "$type", "Point"],
        ["has", "name"],
        ["has", "heading"],
        ["!has", "charge"],
        ["!in", "parking_space", "storage", "bicycle", "kayak", "motorcycle"],
        ["==", "amenity", "parking_space"],
      ],
      layout: {
        visibility: "visible",
        "text-size": ["interpolate", ["linear"], ["zoom"], 17, 1, 24, 40],
        "text-allow-overlap": true,
        "text-transform": "uppercase",
        "text-font": ["Roboto Condensed Bold"],
        "symbol-placement": "point",
        "text-rotate": [
          "-",
          [
            "case",
            [">=", ["get", "heading"], 179],
            ["-", ["get", "heading"], 180],
            ["get", "heading"],
          ],
          90,
        ],
        "text-rotation-alignment": "map",
        "text-padding": 0,
        "text-field": ["get", "name"],
        "text-letter-spacing": 0,
        "text-line-height": 1,
      },
      paint: {
        "text-halo-color": "#fff",
        "text-halo-width": 1,
        "text-color": "#000000",
      },
    },
    {
      id: "parking space label medium",
      type: "symbol",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "$type", "LineString"],
        ["has", "name"],
        ["!has", "charge"],
        ["in", "parking_space", "motorcycle"],
        ["==", "amenity", "parking_space"],
      ],
      layout: {
        "text-size": ["interpolate", ["linear"], ["zoom"], 18, 0, 24, 30],
        "text-allow-overlap": true,
        "text-variable-anchor": ["center"],
        "text-transform": "uppercase",
        "text-font": ["Roboto Condensed Bold"],
        "symbol-placement": "line-center",
        "text-padding": 0,
        "text-rotation-alignment": "map",
        "text-field": ["get", "name"],
        "text-letter-spacing": 0,
        visibility: "visible",
        "text-line-height": 1,
      },
      paint: {
        "text-halo-color": "#fff",
        "text-halo-width": 1,
        "text-color": "#000000",
      },
    },
    {
      id: "parking space label small",
      type: "symbol",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "$type", "LineString"],
        ["has", "name"],
        ["!has", "charge"],
        ["in", "parking_space", "storage", "bicycle", "kayak"],
        ["==", "amenity", "parking_space"],
      ],
      layout: {
        "text-size": ["interpolate", ["linear"], ["zoom"], 19, 0, 24, 20],
        "text-allow-overlap": true,
        "text-variable-anchor": ["center"],
        "text-transform": "uppercase",
        "text-font": ["Roboto Condensed Bold"],
        "symbol-placement": "line-center",
        "text-padding": 0,
        "text-rotation-alignment": "map",
        "text-field": ["get", "name"],
        "text-letter-spacing": 0,
        visibility: "visible",
        "text-line-height": 1,
      },
      paint: {
        "text-halo-color": "#fff",
        "text-halo-width": 1,
        "text-color": "#000000",
      },
    },
    {
      id: "parking space label alt",
      type: "symbol",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "$type", "LineString"],
        ["has", "name"],
        ["==", "amenity", "parking_space_entrance"],
      ],
      layout: {
        "text-size": ["interpolate", ["linear"], ["zoom"], 17, 1, 24, 24],
        "text-allow-overlap": true,
        "text-line-height": 3,
        "text-transform": "uppercase",
        "text-font": ["Roboto Condensed Bold"],
        "symbol-placement": "line-center",
        "text-padding": 0,
        "text-rotation-alignment": "map",
        "text-field": ["get", "name"],
        "text-letter-spacing": 0,
        visibility: "none",
        "text-anchor": "bottom",
      },
      paint: {
        "text-halo-color": "#fff",
        "text-halo-width": 0,
        "text-color": "#888",
      },
    },
  ];
}

function parking_space_icons() {
  return [
    {
      id: "parking compact icon",
      type: "symbol",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "amenity", "parking_space_entrance"],
        ["==", "size", "compact"],
      ],
      layout: {
        "symbol-placement": "line-center",
        "icon-rotation-alignment": "viewport",
        "icon-keep-upright": true,
        "icon-size": ["interpolate", ["linear"], ["zoom"], 17, 0, 24, 0.75],
        "icon-allow-overlap": true,
        "icon-image": "compact-64",
        visibility: "visible",
      },
      paint: {},
    },
    {
      id: "parking oversize icon",
      type: "symbol",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["==", "amenity", "parking_space_entrance"],
        ["in", "size", "van", "oversize", "oversized"],
      ],
      layout: {
        "symbol-placement": "line-center",
        "icon-rotation-alignment": "viewport",
        "icon-keep-upright": true,
        "icon-size": ["interpolate", ["linear"], ["zoom"], 17, 0, 24, 0.75],
        "icon-allow-overlap": true,
        "icon-image": "oversize-64",
        visibility: "visible",
      },
      paint: {},
    },
    {
      id: "parking ada icon",
      type: "symbol",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["match", ["get", "amenity"], ["parking_space_entrance"], true, false],
        ["match", ["get", "capacity:disabled"], ["yes"], true, false],
      ],
      layout: {
        "symbol-placement": "line-center",
        "icon-rotation-alignment": "viewport",
        "icon-keep-upright": true,
        "icon-size": ["interpolate", ["linear"], ["zoom"], 17, 0, 24, 0.75],
        "icon-allow-overlap": true,
        "icon-image": "ada-64",
        visibility: "visible",
      },
      paint: {},
    },
    {
      id: "parking charging icon",
      type: "symbol",
      source: "property",
      minzoom: 15,
      filter: [
        "all",
        ["match", ["get", "amenity"], ["parking_space_rear"], true, false],
        ["match", ["get", "capacity:charging"], ["yes", "1"], true, false],
      ],
      layout: {
        "symbol-placement": "line-center",
        "icon-rotation-alignment": "viewport",
        "icon-keep-upright": true,
        "icon-size": ["interpolate", ["linear"], ["zoom"], 17, 0, 24, 0.625],
        "icon-allow-overlap": true,
        "icon-image": "ev-64",
        visibility: "visible",
      },
      paint: {},
    },
  ];
}

function assemble() {
  return {
    version: 8,
    name: "Greyscale",
    metadata: {},
    bearing: 0,
    pitch: 0,
    sources: {
      satellite: {
        tiles: [
          "https://ecn.t3.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=9781",
        ],
        type: "raster",
        tileSize: 256,
        maxzoom: 20,
      },
      property: {
        type: "geojson",
        generateId: true,
        data: {
          type: "FeatureCollection",
          features: [],
        },
      },
    },
    sprite: "mapbox://sprites/parkingboss/ckkctvfdm0qgw17pfq820rlz2",
    glyphs: "mapbox://fonts/parkingboss/{fontstack}/{range}.pbf",
    layers: [
      {
        id: "land",
        type: "background",
        layout: {},
        paint: { "background-color": "#f5f5f5" },
      },
      {
        id: "satellite",
        type: "raster",
        source: "satellite",
        layout: {
          visibility: "none",
        },
        paint: {},
      },
      {
        id: "property area",
        type: "fill",
        source: "property",
        minzoom: 0,
        filter: ["match", ["get", "landuse"], ["residential"], true, false],
        layout: { visibility: "visible" },
        paint: { "fill-color": "#f5f5f5" },
      },
      {
        id: "natural wood area",
        type: "fill",
        source: "property",
        minzoom: 4,
        filter: ["any", ["==", "natural", "wood"]],
        layout: {},
        paint: {
          "fill-color": "#E6F2EB",
        },
      },
      {
        id: "natural wetland area",
        type: "fill",
        source: "property",
        minzoom: 0,
        filter: ["any", ["==", "natural", "wetland"]],
        layout: {},
        paint: { "fill-color": "#DAE5DF" },
      },
      {
        id: "natural grass area",
        type: "fill",
        source: "property",
        minzoom: 0,
        filter: ["any", ["==", "natural", "grassland"]],
        layout: {},
        paint: { "fill-color": "#EDF2EF" },
      },
      {
        id: "natural water area",
        type: "fill",
        source: "property",
        minzoom: 0,
        filter: ["==", "natural", "water"],
        layout: {},
        paint: { "fill-outline-color": "#dbeaf6", "fill-color": "#dbeaf6" },
      },
      {
        id: "property paved",
        type: "fill",
        source: "property",
        minzoom: 0,
        filter: ["match", ["get", "surface"], ["paved"], true, false],
        layout: {
          visibility: "visible",
        },
        paint: {
          "fill-color": "#fff",
          "fill-opacity": 1,
          "fill-outline-color": "#eee",
        },
      },
      {
        id: "building simple area",
        type: "fill",
        source: "property",
        minzoom: 0,
        filter: [
          "all",
          ["has", "building"],
          ["!in", "parking", "carports", "sheds", "garage_boxes"],
          ["!has", "building:levels"],
        ],
        layout: {},
        paint: {
          "fill-color": "#eee",
          "fill-outline-color": "#ccc",
        },
      },
      {
        id: "building leveled area",
        type: "fill",
        source: "property",
        minzoom: 0,
        filter: ["all", ["has", "building"], ["has", "building:levels"]],
        layout: {},
        paint: {
          "fill-color": "#eee",
          "fill-outline-color": "#444",
        },
      },
      {
        id: "outdoor leisure",
        type: "fill",
        source: "property",
        minzoom: 0,
        filter: [
          "all",
          ["has", "leisure"],
          ["!has", "level"],
          ["!has", "building"],
        ],
        layout: {},
        paint: { "fill-color": colors.grass },
      },
      {
        id: "outdoor patio",
        type: "fill",
        source: "property",
        minzoom: 0,
        filter: ["all", ["==", "leisure", "outdoor_seating"]],
        layout: {},
        paint: { "fill-color": colors.paving_stones, "fill-opacity": 1 },
      },
      {
        id: "outdoor level grass area",
        type: "fill",
        source: "property",
        minzoom: 0,
        filter: ["all", ["==", "surface", "grass"]],
        layout: {},
        paint: { "fill-color": colors.grass },
      },
      {
        id: "!outdoor level flowerbed area",
        type: "fill",
        source: "property",
        minzoom: 0,
        filter: ["all", ["==", "landuse", "flowerbed"]],
        layout: {},
        paint: { "fill-color": colors.grass },
      },
      {
        id: "outdoor level pool",
        type: "fill",
        source: "property",
        minzoom: 15,
        filter: ["all", ["==", "leisure", "swimming_pool"]],
        layout: {},
        paint: { "fill-color": "#ABE1F4", "fill-opacity": 1 },
      },
      {
        id: "building-parking-area",
        type: "fill",
        source: "property",
        minzoom: 15,
        filter: ["all", ["==", "amenity", "parking"], ["has", "building"]],
        layout: { visibility: "visible" },
        paint: { "fill-color": "#fff" },
      },
      {
        id: "dogpark",
        type: "fill",
        source: "property",
        minzoom: 15,
        filter: ["match", ["get", "leisure"], ["dog_park"], true, false],
        layout: {},
        paint: { "fill-color": colors.grass, "fill-opacity": 1 },
      },
      {
        id: "playground",
        type: "fill",
        source: "property",
        minzoom: 15,
        filter: ["match", ["get", "leisure"], ["playground"], true, false],
        layout: {},
        paint: { "fill-color": colors.grass, "fill-opacity": 1 },
      },
      {
        id: "highway footway base",
        type: "line",
        source: "property",
        minzoom: 15,
        filter: ["match", ["get", "highway"], ["footway"], true, false],
        paint: {
          "line-color": "#fff",
          "line-width": 4,
          "line-opacity": 1,
        },
      },
      {
        id: "highway footway",
        type: "line",
        source: "property",
        minzoom: 15,
        filter: ["match", ["get", "highway"], ["footway"], true, false],
        paint: {
          "line-color": "#aaa",
          "line-dasharray": [1.5, 0.75],
          "line-width": 1.1,
          "line-opacity": 1,
        },
      },
      {
        id: "building label",
        type: "symbol",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["has", "name"],
          ["has", "building"],
          ["==", "$type", "Point"],
          ["!=", "name", ""],
        ],
        layout: {
          "text-field": { type: "identity", property: "name" },
          "text-font": ["Roboto Bold"],
          "text-transform": "uppercase",
          "text-size": ["interpolate", ["linear"], ["zoom"], 16, 2, 24, 48],
          "text-max-width": 6,
        },
        paint: {
          "text-color": "#444",
          "text-halo-color": "#fff",
          "text-halo-width": 1,
        },
      },
      {
        id: "indoor level area",
        type: "fill",
        source: "property",
        minzoom: 15,
        filter: ["all", ["==", "indoor", "level"]],
        layout: {},
        paint: {
          "fill-color": "#fff",
          "fill-outline-color": "#444",
          "fill-opacity": 1,
        },
      },
      ...level_area(),
      {
        id: "indoor area",
        type: "fill",
        source: "property",
        minzoom: 15,
        filter: ["all", ["in", "indoor", "area", "room", "corridor"]],
        layout: {},
        paint: { "fill-color": "#fff", "fill-opacity": 1 },
      },
      {
        id: "indoor level grass area",
        type: "fill",
        source: "property",
        minzoom: 0,
        filter: ["all", ["has", "level"], ["==", "surface", "grass"]],
        layout: {},
        paint: { "fill-color": colors.grass },
      },
      {
        id: "indoor level flowerbed area",
        type: "fill",
        source: "property",
        minzoom: 0,
        filter: ["all", ["has", "level"], ["==", "landuse", "flowerbed"]],
        layout: {},
        paint: { "fill-color": colors.grass },
      },
      {
        id: "indoor level pool",
        type: "fill",
        source: "property",
        minzoom: 15,
        filter: ["all", ["has", "level"], ["==", "leisure", "swimming_pool"]],
        layout: {},
        paint: { "fill-color": "#ABE1F4", "fill-opacity": 1 },
      },
      {
        id: "indoor unit availability area",
        type: "fill",
        source: "property",
        minzoom: 15,
        filter: ["all", ["has", "addr:unit"], ["has", "available"]],
        layout: {},
        paint: {
          "fill-color": [
            "case",
            ["==", ["get", "available"], "no"],
            "#ffe8e5",
            "#cee5d8",
          ],
        },
      },
      {
        id: "indoor unit parking permitted area",
        type: "fill",
        source: "property",
        minzoom: 15,
        filter: ["all", ["has", "addr:unit"], ["has", "parking:permit"]],
        layout: {},
        paint: {
          "fill-color": [
            "case",
            ["==", ["get", "parking:permit:vehicle"], "yes"],
            "#ffe8e5",
            ["==", ["get", "parking:permit"], "yes"],
            "#ffebcc",
            "#cee5d8",
          ],
        },
      },
      {
        id: "indoor level building part outline",
        type: "line",
        source: "property",
        minzoom: 15,
        filter: ["all", ["has", "building:part"]],
        layout: { visibility: "visible" },
        paint: {
          "line-width": 1,
          "line-dasharray": [3, 3],
          "line-offset": -0.5,
          "line-color": "#888",
          "line-opacity": 0.5,
        },
      },
      {
        id: "indoor wall area",
        type: "fill",
        source: "property",
        minzoom: 15,
        layout: { visibility: "none" },
        filter: ["all", ["==", "$type", "Polygon"], ["==", "indoor", "wall"]],
        paint: { "fill-color": "#B0B0B0" },
      },
      {
        id: "indoor wall outline",
        type: "line",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["==", "indoor", "wall"],
          ["==", "$type", "LineString"],
        ],
        layout: {},
        paint: { "line-width": 1, "line-color": "#B0B0B0" },
      },
      {
        id: "indoor area outline",
        type: "line",
        source: "property",
        minzoom: 15,
        filter: ["all", ["in", "indoor", "room", "level"]],
        layout: {},
        paint: { "line-width": 1, "line-color": "#B0B0B0" },
      },
      {
        id: "indoor unit outline",
        type: "line",
        source: "property",
        minzoom: 15,
        filter: ["all", ["has", "addr:unit"]],
        layout: {},
        paint: { "line-width": 1, "line-color": "#B0B0B0" },
      },
      {
        id: "indoor outdoor level outline",
        type: "line",
        source: "property",
        minzoom: 15,
        filter: ["any", ["==", "indoor", "level"], ["==", "outdoor", "level"]],
        layout: {
          visibility: "visible",
        },
        paint: {
          "line-width": 1,
          "line-color": "#1e1e1e",
          "line-offset": 0,
          "line-dasharray": [
            "case",
            ["==", ["get", "building:part"], "carports"],
            ["literal", [3, 3]],
            ["literal", [0, 0]],
          ],
        },
      },
      {
        id: "indoor room label",
        type: "symbol",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["has", "name"],
          ["has", "level"],
          ["==", "indoor", "room"],
          ["==", "$type", "Point"],
          ["!=", "name", ""],
        ],
        layout: {
          "text-field": { type: "identity", property: "name" },
          "text-font": ["Roboto Regular"],
          "text-transform": "uppercase",
          "text-size": ["interpolate", ["linear"], ["zoom"], 16, 0, 24, 24],
          "text-max-width": 6,
          visibility: "none",
        },
        paint: {
          "text-color": "#888",
          "text-halo-color": "#fff",
          "text-halo-width": 1,
        },
      },
      {
        id: "indoor unit label no availability",
        type: "symbol",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["has", "addr:unit"],
          ["!has", "available"],
          ["==", "$type", "Point"],
          ["!=", "addr:unit", ""],
        ],
        layout: {
          "text-field": ["get", "addr:unit"],
          "text-font": ["Roboto Regular"],
          "text-transform": "uppercase",
          "text-size": ["interpolate", ["linear"], ["zoom"], 16, 0, 24, 32],
          "text-max-width": 6,
        },
        paint: {
          "text-color": "#888",
          "text-halo-color": "#fff",
          "text-halo-width": 1,
        },
      },
      {
        id: "indoor unit label unavailable",
        type: "symbol",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["has", "addr:unit"],
          ["==", "available", "no"],
          ["==", "$type", "Point"],
          ["!=", "addr:unit", ""],
        ],
        layout: {
          "text-field": ["get", "addr:unit"],
          "text-font": ["Roboto Regular"],
          "text-transform": "uppercase",
          "text-size": ["interpolate", ["linear"], ["zoom"], 16, 0, 24, 32],
          "text-max-width": 6,
        },
        paint: {
          "text-color": "#444",
          "text-halo-color": "#fff",
          "text-halo-width": 1,
        },
      },
      {
        id: "indoor unit label available",
        type: "symbol",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["has", "addr:unit"],
          ["has", "available"],
          ["!=", "available", "no"],
          ["==", "$type", "Point"],
          ["!=", "addr:unit", ""],
        ],
        layout: {
          "text-ignore-placement": true,
          "text-radial-offset": 0.5,
          "text-field": ["get", "addr:unit"],
          "text-font": ["Roboto Bold"],
          "text-transform": "uppercase",
          "text-size": ["interpolate", ["linear"], ["zoom"], 16, 0, 24, 48],
          "text-max-width": 6,
          "text-anchor": "center",
        },
        paint: {
          "text-color": "#444",
          "text-halo-color": "#fff",
          "text-halo-width": 1,
        },
      },
      {
        id: "indoor unit label price",
        type: "symbol",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["has", "addr:unit"],
          ["has", "available"],
          ["has", "charge"],
          ["==", "$type", "Point"],
          ["!=", "addr:unit", ""],
        ],
        layout: {
          "text-radial-offset": 1,
          "text-ignore-placement": true,
          "text-field": ["get", "charge"],
          "text-font": ["Roboto Regular"],
          "text-transform": "uppercase",
          "text-size": ["interpolate", ["linear"], ["zoom"], 16, 0, 24, 32],
          "text-max-width": 6,
          "text-anchor": "top",
        },
        paint: {
          "text-color": "#444",
          "text-halo-color": "#fff",
          "text-halo-width": 1,
        },
      },
      {
        id: "building outline",
        type: "line",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["has", "building"],
          ["!in", "building", "carport", "garage", "garages"],
          ["!in", "parking", "sheds", "carports", "garage_boxes"],
        ],
        layout: { "line-join": "miter", visibility: "none" },
        paint: {
          "line-color": "#444",
          "line-width": ["interpolate", ["linear"], ["zoom"], 16, 0, 24, 4],
          "line-offset": ["interpolate", ["linear"], ["zoom"], 16, -0, 24, -2],
        },
      },
      {
        id: "building levels outline",
        type: "line",
        source: "property",
        minzoom: 15,
        filter: ["all", ["has", "building:levels"]],
        layout: { "line-join": "miter", visibility: "none" },
        paint: {
          "line-color": "#444",
          "line-width": ["interpolate", ["linear"], ["zoom"], 16, 0, 24, 4],
          "line-offset": ["interpolate", ["linear"], ["zoom"], 16, -0, 24, -2],
        },
      },
      {
        id: "parking no area",
        type: "fill",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["match", ["get", "amenity"], ["parking"], true, false],
          ["match", ["get", "access"], ["no"], true, false],
        ],
        layout: {
          visibility: "visible",
        },
        paint: {
          "fill-pattern": "noparking4",
          "fill-antialias": false,
          "fill-opacity": 1,
        },
      },
      {
        id: "parking no area outline",
        type: "line",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["match", ["get", "parking"], ["surface"], true, false],
          ["match", ["get", "access"], ["no"], true, false],
        ],
        layout: {
          visibility: "none",
        },
        paint: {
          "line-color": "#CCC",
          "line-width": 1,
          "line-offset": 0,
        },
      },
      ...parking_space_outline(),
      ...parking_space_charge(),
      {
        id: "parking building carport outline track",
        type: "line",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["==", "amenity", "parking"],
          ["has", "building"],
          ["==", "parking", "carports"],
        ],
        layout: { visibility: "visible" },
        paint: {
          "line-color": "#fff",
          "line-opacity": 1,
          "line-width": 4,
          "line-offset": 0,
        },
      },
      {
        id: "parking building carport outline",
        type: "line",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["==", "amenity", "parking"],
          ["has", "building"],
          ["==", "parking", "carports"],
        ],
        layout: { visibility: "visible" },
        paint: {
          "line-color": "#444",
          "line-opacity": 1,
          "line-width": 1.2,
          "line-dasharray": [3, 2],
          "line-offset": 0,
        },
      },
      {
        id: "parking building garage outline",
        type: "line",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["has", "building"],
          ["in", "parking", "sheds", "garage_boxes"],
          ["==", "amenity", "parking"],
        ],
        layout: { visibility: "visible" },
        paint: {
          "line-color": "#444",
          "line-opacity": 1,
          "line-width": 1,
          "line-offset": 0,
        },
      },
      ...parking_space_label(),
      {
        id: "property outline outer",
        type: "line",
        source: "property",
        filter: ["match", ["get", "landuse"], ["residential"], true, false],
        layout: {
          visibility: "visible",
        },
        paint: {
          "line-color": "#fff",
          "line-width": 3,
          "line-offset": 0,
        },
      },
      {
        id: "property outline",
        type: "line",
        source: "property",
        filter: ["match", ["get", "landuse"], ["residential"], true, false],
        layout: {
          visibility: "visible",
        },
        paint: {
          "line-color": "#000",
          "line-width": 1,
          "line-offset": 0,
          "line-opacity": 0.5,
        },
      },
      {
        id: "parking zone outline",
        type: "line",
        source: "property",
        filter: [
          "all",
          ["==", "amenity", "parking"],
          ["has", "name"],
          ["!has", "building"],
          ["!has", "building:part"],
          ["!=", "name", ""],
          ["!in", "indoor", "room"],
        ],
        paint: {
          "line-dasharray": [2, 2],
          "line-opacity": 0.5,
          "line-gap-width": 0,
          "line-color": "#cc1800",
        },
      },
      {
        id: "parking zone label",
        type: "symbol",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["==", "amenity", "parking"],
          ["has", "name"],
          ["!=", "name", ""],
          ["!has", "building"],
          ["!has", "building:part"],
          ["!in", "indoor", "room"],
        ],
        layout: {
          "text-font": ["Roboto Bold"],
          "text-field": { type: "identity", property: "name" },
          "symbol-placement": "line",
          "text-transform": "uppercase",
          "text-letter-spacing": 0.1,
          "symbol-avoid-edges": true,
          "text-keep-upright": false,
          "text-rotation-alignment": "auto",
          "text-pitch-alignment": "auto",
          "text-padding": 0,
          "text-anchor": "bottom",
          "text-line-height": 1,
          "symbol-spacing": 250,
          "text-size": 8,
        },
        paint: {
          "text-halo-color": "#fff",
          "text-translate": [0, 0],
          "text-color": "#cc1800",
          "text-halo-width": 1.5,
          "text-halo-blur": 0.5,
        },
      },
      {
        id: "parking building carport label",
        type: "symbol",
        source: "property",
        minzoom: 17.5,
        filter: [
          "all",
          ["==", "amenity", "parking"],
          ["has", "building"],
          ["==", "parking", "carports"],
        ],
        layout: {
          visibility: "none",
          "text-font": ["Roboto Regular"],
          "text-field": "carport",
          "symbol-placement": "line",
          "text-transform": "uppercase",
          "text-letter-spacing": 0.1,
          "symbol-avoid-edges": true,
          "text-keep-upright": false,
          "text-rotation-alignment": "auto",
          "text-pitch-alignment": "auto",
          "text-padding": 0,
          "text-anchor": "bottom",
          "text-line-height": 1,
          "symbol-spacing": 150,
          "text-size": 8,
        },
        paint: {
          "text-halo-color": "#fff",
          "text-translate": [0, 0],
          "text-color": "#b0b0b0",
          "text-halo-width": 1.5,
          "text-halo-blur": 0.5,
        },
      },
      {
        id: "ramp",
        type: "symbol",
        source: "property",
        filter: [
          "all",
          ["==", "$type", "LineString"],
          ["==", "indoor", "ramp"],
        ],
        layout: {
          "text-field": "RAMP",
          "text-font": ["Roboto Bold"],
          "text-letter-spacing": 0.25,
          "text-transform": "uppercase",
          "symbol-placement": "line-center",
          "text-size": 14,
        },
        paint: { "text-opacity": 1, "text-color": "#aaa" },
      },
      {
        id: "gate",
        type: "line",
        source: "property",
        minzoom: 15,
        filter: ["match", ["get", "barrier"], ["gate"], true, false],
        layout: {},
        paint: { "line-gap-width": 2, "line-color": "#cc1800" },
      },
      {
        id: "building part label",
        type: "symbol",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["has", "name"],
          ["has", "building:part"],
          ["==", "$type", "Point"],
          ["!=", "name", ""],
        ],
        layout: {
          visibility: "none",
          "text-field": { type: "identity", property: "name" },
          "text-font": ["Roboto Bold"],
          "text-transform": "uppercase",
          "text-size": ["interpolate", ["linear"], ["zoom"], 17, 0, 24, 48],
          "text-max-width": 6,
        },
        paint: {
          "text-color": "#444",
          "text-halo-color": "#fff",
          "text-halo-width": 1,
        },
      },
      ...available(),
      ...selected(),
      ...highlighted(),
      ...parking_space_icons(),
      {
        id: "steps elevator icon",
        type: "symbol",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["in", "highway", "elevator", "steps"],
          ["==", "$type", "Point"],
        ],
        layout: {
          "text-field": { type: "identity", property: "name" },
          "text-font": ["Roboto Bold"],
          "text-transform": "uppercase",
          "text-size": ["interpolate", ["linear"], ["zoom"], 17, 0, 24, 40],
          "text-radial-offset": 0,
          "text-line-height": 1.5,
          "text-max-width": 6,
          "symbol-placement": "point",
          "icon-rotation-alignment": "viewport",
          "icon-keep-upright": true,
          "icon-anchor": ["case", ["has", "name"], "bottom", "center"],
          "text-anchor": ["case", ["has", "name"], "top", "center"],
          "icon-size": ["interpolate", ["linear"], ["zoom"], 17, 0, 24, 0.9],
          "icon-allow-overlap": true,
          "icon-image": ["concat", ["get", "highway"], "-64"],
          visibility: "visible",
        },
        paint: {
          "text-color": "#888",
          "text-halo-color": "#fff",
          "text-halo-width": 1,
        },
      },
      ...parking_entrance,
      ...entrance(),
      {
        id: "waste icon",
        type: "symbol",
        source: "property",
        minzoom: 15,
        filter: [
          "all",
          ["==", "amenity", "waste_disposal"],
          ["==", "$type", "Point"],
        ],
        layout: {
          "symbol-placement": "point",
          "icon-rotation-alignment": "viewport",
          "icon-keep-upright": true,
          "icon-size": ["interpolate", ["linear"], ["zoom"], 17, 0, 24, 0.9],
          "icon-allow-overlap": true,
          "icon-image": "trash-64",
          visibility: "visible",
        },
        paint: {},
      },
      ...fire_hydrant,
      {
        id: "restroom unisex icon",
        type: "symbol",
        source: "property",
        minzoom: 15,
        filter: ["all", ["==", "amenity", "toilets"], ["==", "$type", "Point"]],
        layout: {
          "icon-rotation-alignment": "viewport",
          "icon-keep-upright": true,
          "icon-size": ["interpolate", ["linear"], ["zoom"], 17, 0, 24, 0.9],
          "icon-allow-overlap": true,
          "icon-image": "restroom-64",
          visibility: "visible",
        },
        paint: {},
      },
      {
        id: "selectable",
        type: "fill",
        source: "property",
        filter: ["all", ["has", "selectable"], ["==", "$type", "Polygon"]],
        minzoom: 15,
        paint: {
          "fill-color": "red",
          "fill-opacity": 0,
        },
      },
    ],
  };
}

const style = assemble;

export default style;
